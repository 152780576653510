<template>
  <div class="Home">
    <page-header/>
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <div class="uk-width-1-1 uk-visible@m" uk-grid>
          <div class="uk-width-4-5">
            <input v-model="exam.examName"
                   class="uk-input enterExamCode"
                   :placeholder="$t('additional.examname')"
                   type="text"/>
            <!--          <select class="uk-select select-search" v-model="exam.status">-->
            <!--            <option disabled value="">{{ $t("additional.status") }}</option>-->
            <!--            <option :value="1">{{ $t("additional.ready") }}</option>-->
            <!--            <option :value="2">{{ $t("additional.start") }}</option>-->
            <!--            <option :value="3">{{ $t("additional.finish") }}</option>-->
            <!--            <option :value="4">{{ $t("additional.end") }}</option>-->
            <!--          </select>-->
            <date-picker
                v-model="exam.date"
                type="date"
                style="height: 46px !important; border-radius: 30px !important;"
                class="datepicker"
                :placeholder="$t('startDate')"
            ></date-picker>
<!--            <date-picker-->
<!--                v-model="exam.endDate"-->
<!--                type="date"-->
<!--                style="height: 46px !important; border-radius: 30px !important;"-->
<!--                class="datepicker"-->
<!--                :placeholder="$t('endDate')"-->
<!--            ></date-picker>-->
          </div>
          <div class="uk-flex uk-flex-right uk-width-1-5">
            <span uk-icon="search" class="searchIcon" @click="searchFilter()" style="cursor: pointer"></span>
            <span uk-icon="refresh" class="searchIcon" @click="searchDefault()" style="cursor: pointer"></span>
          </div>
        </div>
      </div>
    </section>
    <section class="uk-section uk-padding-small" style="min-height: 600px;">
      <div class="uk-container">
<!--        <div class="uk-card uk-card-default uk-box-shadow-medium">-->
<!--          <div class="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center" uk-grid>-->
<!--            <div v-for="(item, index) in examDetail" :key="index">-->
<!--              <div class="uk-card uk-card-default uk-card-body">-->
<!--                <div class="uk-card-header uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right">-->
<!--                  <div class="uk-grid-small uk-flex-middle" uk-grid>-->
<!--                    <div class="uk-width-expand">-->
<!--                      <h3 class="uk-card-title uk-margin-remove-bottom">-->
<!--                        <span v-if="item.exam_name.length > 10">-->
<!--                          {{ item.exam_name.substring(0,10) }}...-->
<!--                        </span>-->
<!--                        <span v-else>-->
<!--                           {{ item.exam_name}}-->
<!--                        </span>-->
<!--                      </h3>-->
<!--                      <h5 class="uk-margin-remove-bottom uk-margin-small-top">-->
<!--                       Start : <time datetime="2016-04-01T19:00">{{ item.start_date | moment }} </time></h5>-->
<!--                      <h5 class="uk-margin-remove-top">-->
<!--                        End : <time datetime="2016-04-01T19:00">{{ item.end_date | moment }} </time>-->
<!--                      </h5>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="uk-card-body uk-text-left">-->
<!--                  <ul class="uk-list uk-list-square">-->
<!--                    <li><h4>Group name : <em>{{ item.group_name }}</em></h4> </li>-->
<!--                    <li><h4>Student Count : <em>{{ item.st_cnt }}</em></h4></li>-->
<!--                    <li><h4>Exam Time : <em>{{ item.exam_time }} m</em></h4></li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--                <div class="uk-card-footer uk-padding-small uk-padding-remove-bottom">-->
<!--                  <button class="uk-button uk-button-primary uk-width-1-1">Show</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <table class="uk-table uk-table-striped uk-table-responsive uk-table-small">
            <thead style="background: #01b0ff">
            <tr>
              <template v-for="(pitem,index) in fields" :item="pitem">
                <th v-if="pitem.sortuse == true" @click="orderTable(pitem)" :key="index"
                    class="uk-text-center">
                  <div class="uk-flex uk-flex-center">
                    <span> {{ $t('proctor.fields[' + index + '].label') }}</span>
                    <img v-if="pitem.sorted && pitem.direction == 'desc'" style="margin-left: 10px;"
                         src="../../assets/icons/desc.png" alt="" width="25" height="25"/>
                    <img v-if="pitem.sorted && pitem.direction == 'asc'" style="margin-left: 10px;"
                         src="../../assets/icons/asc.png" alt="" width="25" height="25"/>
                    <img v-if="pitem.sorted == false" src="../../assets/icons/sort1.png" alt=""
                         width="25" height="25" style="margin-left: 10px;"/>
                  </div>
                </th>
                <th v-else :key="index">
                  <span> {{ $t('proctor.fields[' + index + '].label') }}</span>
                </th>
              </template>
            </tr>
            </thead>
            <tbody v-if="examDetail.length > 0">
            <tr class="uk-text-center" v-for="(item, index) in examDetail" :key="index">
              <td>
                    <span v-if="currentPage == 0">
                        {{ index + 1 }}
                      </span>
                <span v-else>
                        {{ ((currentPage * size) + index) - size + 1 }}
                      </span>
              </td>
              <td>
                <span>{{ item.start_date | moment }} ~ {{ item.end_date  | moment }}</span>
              </td>
              <td>
                <span>{{ item.exam_time }}</span>
              </td>
              <td class="uk-table-expand">
                <span>{{ item.exam_name }}</span>
              </td>
              <td>
                <span>{{ item.st_cnt }}</span>
              </td>
              <td>
                <span>{{ item.group_name }}</span>
              </td>
              <td>
                <h5 class="uk-text-capitalize" v-if="item.exam_status == 'start'">
                  {{ $t("prStart") }}
                </h5>
                <h5 class="uk-text-capitalize" v-else>
                  {{ $t("cha") }}
                </h5>
              </td>
              <td>
                <router-link class="uk-button uk-button-primary" tag="a"
                   :to="{name: 'OrganizationExamineeResultGrid2', query: { groupId: item.group_id, examId: item.exam_id}}">
                  {{ $t("monitor") }}
                </router-link>
<!--                <button class="uk-button uk-button-default uk-button-small"-->
<!--                        @click="gotoDetails(item.examId, item.group_id)">-->
<!--                  {{ $t("monitor") }}-->
<!--                </button>-->
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td class="uk-alert-primary" uk-alert colspan="11">
                <p class="uk-text-center">{{ $t("orgaExam.orgaList.notFound") }}</p>
              </td>
            </tr>
            </tbody>
          </table>
<!--        </div>-->
        <!--        <div id="examGroup" uk-modal>-->
        <!--          <div class="uk-modal-dialog" style="width: 90%;">-->
        <!--            <button class="uk-modal-close-default"  uk-close></button>-->
        <!--            <div class="uk-modal-header">-->
        <!--              <h2 class="uk-modal-title uk-text-center"-->
        <!--                  style="font-size: 21px; font-weight: 700;">Exam Group</h2>-->
        <!--            </div>-->
        <!--            <div class="uk-modal-body uk-text-center">-->
        <!--              <group-data></group-data>-->
        <!--            </div>-->
        <!--            <div class="uk-modal-footer uk-text-right">-->
        <!--              <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >-->
        <!--                {{ $t('close') }}-->
        <!--              </button>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- end sendExamCode -->
      </div>
    </section>
    <section class="uk-section uk-padding-remove ">
      <div class="uk-container">
        <div class="uk-card-footer uk-text-center">
          <div class="uk-flex uk-flex-center">
            <v-pagination
                v-model="currentPage"
                :page-count="pages"
                :classes="uikitClasses"
                :labels="Labels"
                @change="onChange"
            ></v-pagination>
          </div>
        </div>
      </div>
    </section>
    <page-footer/>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import vPagination from "@/components/globals/vue-plain-pagination"
import ExamService from '@/services/ExamService'

// import UIkit from 'uikit'
import moment from "moment";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
// import UserService from '@/services/UserService'
// import ResearchService from "../../services/ResearchService";
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
// import GroupData from '@/components/Organizcmp/OrganizStGroupList.vue'

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");

export default {
  name: "Home",
  components: {
    DatePicker,
    vPagination,
    // GroupData,
    PageHeader,
    PageFooter,
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken", "currentUser"]),
  },
  data() {
    return {
      examId: 0,
      currentPage: 1,
      pages: 0,
      lang: {
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
      },
      uikitClasses: {
        ul: "uk-pagination",
        li: "",
        liActive: "uk-active",
        liDisable: "uk-disabled",
        button: "page-link"
      },
      Labels: {
        first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
        prev: "<span uk-pagination-previous></span>",
        next: "<span uk-pagination-next></span>",
        last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
      },
      exam: {
        examName: '',
        date: '',
        startDate: '',
        endDate: '',
        realExam: 0,
        status: '',
        gived: 0,
        todayExam: '',
        tomorrowExam: '',
        ungived: ''
      },
      fields: [
        {
          name: "id",
          sortuse: false,
        },
        {
          name: "e.start_date",
          label: "시험일자",
          sortuse: true,
          direction: "",
          sorted: false
        },
        {
          name: "time",
          label: "",
          sortuse: false,
        },
        {
          name: "e.exam_name",
          label: "시험명",
          sortuse: true,
          direction: "",
          sorted: false
        },
        {
          name: "time",
          label: "",
          sortuse: false,
        },
        {
          name: "time",
          label: "",
          sortuse: false,
        },
        {
          name: "time",
          label: "",
          sortuse: false,
        },
        {
          name: "none",
          label: "시험상대",
          sortuse: false,
        },
      ],
      size: 10,
      sorts: [],
      examDetail: []
    };
  },
  created() {
  },
  mounted() {
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD HH:mm')
    }
  },
  methods: {
    // gotoDetails(examId, groupId){
    //   window.location.href =  "/"
    // },
    ublMeet(examId, groupId,sd) {
      if (sd == 'tab') {
        var roomID = groupId + "_" + examId

        /* eslint-disable */
        OpenMeet(roomID, null, this.currentUser.firstName, this.currentUser.username)
      } else {

        var roomID = groupId + "_" + examId + "_tab"

        /* eslint-disable */
        OpenTab(roomID, null, this.currentUser.firstName, this.currentUser.username)
      }
    },
    orderTable(field) {
      if (field.direction == "") {
        field.direction = "asc";
        field.sorted = true;
      } else if (field.direction == "asc") {
        field.direction = "desc";
        field.sorted = true;
      } else if (field.direction == "desc") {
        field.direction = "";
        field.sorted = false;
      }
      // omno sort hisn baigaag tsewerleed arrayd suuliin utga bolgoj hiih
      let check1 = field.name + ",asc";
      let check2 = field.name + ",desc";

      if (this.sorts.includes(check1)) {
        let ind1 = this.sorts.indexOf(check1);
        this.sorts.splice(ind1, 1);
      }
      if (this.sorts.includes(check2)) {
        let ind2 = this.sorts.indexOf(check2);
        this.sorts.splice(ind2, 1);
      }
      if (field.sorted) {
        let sitem = field.name + "," + field.direction;
        this.sorts.push(sitem);
      }

      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    searchDefault() {
      window.location.reload()
    },
    searchFilter() {
      this.currentPage = 1
      this.loaddata(this.currentPage);
    },
    async loaddata(page) {
      try {

        if (page > 0) {
          page = page - 1;
        }

        let sort = "";
        for (let i = 0; i < this.sorts.length; i++) {
          sort += this.sorts[i] + "&";
        }

        let item = {
          page: page,
          examName: this.exam.examName,
          date: this.exam.date,
          sort: sort,
          size: this.size,
          orgId: this.currentUser.organizs[0].organizId
        };

        const response = await ExamService.getProctorExamList(item);

        if (response.status == 200) {
          this.examDetail = response.data.content;
          this.pages = response.data.totalPages;

          if (!this.examDetail.length) {
            this.isLoading = false;
          }
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.scrollToTop();
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onChange: function () {
      this.loaddata(this.currentPage);
    },
  },
}
</script>

<style scoped>
.searchIcon >>> svg {
  width: 30px;
  margin: 9px;
}

.datepicker >>> input {
  height: 46px !important;
  border-radius: 30px !important;
  text-align: center;
  color: #0d9df9;
  border: none;
}

.datepicker >>> input::placeholder {
  color: #0d9df9;
}

.datepicker >>> .mx-input-append {
  margin-right: 18px;
}

.enterExamCode {
  width: 335px;
  height: 46px;
  text-align: center;
  border-radius: 30px;
  margin-right: 10px;
}

.enterExamCode::placeholder {
  font-size: 14px;
  color: #0d9df9;
}

.uk-card-default {
  border: 1px solid;
  border-color: white;
  border-radius: 15px;
}

.qList h2 {
  height: 30px;
  font-size: 1.6em;
  font-weight: 800;
  color: rgb(1, 176, 255);
}

button {
  cursor: pointer;
}

tr td {
  vertical-align: middle;
}

tr th {
  text-align: center;
}

.uk-table td {
  padding: 14px 10px !important;
}

.select-search {
  width: 360px;
  margin-right: 10px;
  height: 42px;
  border-radius: 30px;
  text-align: center;
  align-content: center;
  align-items: center;
  color: rgb(1, 176, 255);
  padding: 0px 1px 0px 1px;
}

.select-search option {
  color: #0d9df9;
}

.uk-select:not([multiple]):not([size]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 20px;
  height: 46px;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
  /*background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A),linear-gradient(#01b0ff, #38b8f2);*/
  background-repeat: no-repeat;
  background-position: 80% 50%;
}

.uk-button {
  padding: 6px;
}
</style>
